var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value.length > 0 || _vm.edit)?_c('div',[_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.$t("asset.assets"))+" ")]),_c('v-divider',{staticClass:"mx-2"}),(_vm.value.length > 0)?_c('v-list',{attrs:{"flat":!_vm.edit}},_vm._l((_vm.value),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',{staticClass:"ma-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.getIconDefinition(item.icon).icon)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getIconDefinition(item.icon).title))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-2",domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[(!_vm.edit)?_c('v-btn',{attrs:{"color":"primary","icon":"","href":_vm.assetURL(item.fileName),"target":"_blank","top":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$globals.icons.download)+" ")])],1):_c('div',[_c('v-btn',{attrs:{"color":"error","icon":"","top":""},on:{"click":function($event){return _vm.deleteAsset(i)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$globals.icons.delete))])],1),_c('TheCopyButton',{attrs:{"copy-text":_vm.copyLink(item.fileName)}})],1)],1)],1)}),1):_vm._e()],1),_c('div',{staticClass:"d-flex ml-auto mt-2"},[_c('v-spacer'),_c('base-dialog',{attrs:{"title":_vm.$t('asset.new-asset'),"title-icon":_vm.getIconDefinition(_vm.newAsset.icon).icon},on:{"submit":_vm.addAsset},scopedSlots:_vm._u([{key:"open",fn:function(ref){
var open = ref.open;
return [(_vm.edit)?_c('v-btn',{attrs:{"color":"secondary","dark":""},on:{"click":open}},[_c('v-icon',[_vm._v(_vm._s(_vm.$globals.icons.create))])],1):_vm._e()]}}],null,false,657448999)},[_c('v-card-text',{staticClass:"pt-2"},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('general.name')},model:{value:(_vm.newAsset.name),callback:function ($$v) {_vm.$set(_vm.newAsset, "name", $$v)},expression:"newAsset.name"}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-select',{staticClass:"mr-2",attrs:{"dense":"","prepend-icon":_vm.getIconDefinition(_vm.newAsset.icon).icon,"items":_vm.iconOptions,"item-text":"title","item-value":"name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-icon',{staticClass:"mr-auto"},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_vm._v(" "+_vm._s(item.title)+" ")]}}],null,false,1829556531),model:{value:(_vm.newAsset.icon),callback:function ($$v) {_vm.$set(_vm.newAsset, "icon", $$v)},expression:"newAsset.icon"}}),_c('TheUploadBtn',{attrs:{"post":false,"file-name":"file","text-btn":false},on:{"uploaded":_vm.setFileObject}})],1),_vm._v(" "+_vm._s(_vm.fileObject.name)+" ")],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }